import { LoadingIndicator } from './LoadingIndicator';

export const BUTTON_STYLES = Object.freeze({
  DANGER: ' bg-red-600 hover:bg-red-700 text-white rounded ',
  DANGER_SECONDARY:
    ' border-red-600 text-red-600 bg-transparent border-2  rounded ',
  DANGER_TERTIARY:
    ' text-gray-500 bg-transparent border-transparent border-2  rounded  hover:!border-red-600 hover:!bg-red-600 hover:text-white ',
  SECONDARY: ' border-blue-600 text-blue-600 bg-transparent border-2  rounded ',
  TERTIARY: ' border-gray-300 text-slate-900 bg-transparent border  rounded ',
  DEFAULT: ' bg-blue-600 hover:bg-blue-700 text-white rounded ',
  GOOGLE_STYLE:
    ' google-button bg-transparent border-2 text-white rounded  hover:bg-transparent',
  NAKED: ' bg-transparent rounded  ',
  PILL: ' bg-transparent text-gray-500 border-2 border-gray-300 rounded-full py-1',
  BLACK: ' bg-black text-white rounded hover:bg-opacity-80',
});

const Button = ({
  children,
  className = '',
  disabled = false,
  loading = false,
  large = false,
  small = false,
  naked = false,
  secondary = false,
  tertiary = false,
  danger = false,
  fullWidth = false,
  pill = false,
  pillNaked = false,
  black = false,
  ...props
}) => {
  let cN =
    ' inline-flex relative items-center justify-center font-medium tracking-wide transition duration-100   focus:outline-none whitespace-nowrap ';

  if (danger) {
    if (tertiary) {
      cN += ' ' + BUTTON_STYLES.DANGER_TERTIARY;
    } else if (secondary) {
      cN += BUTTON_STYLES.DANGER_SECONDARY;
    } else {
      cN += BUTTON_STYLES.DANGER;
    }
  } else if (naked) {
    cN += ' ' + BUTTON_STYLES.NAKED;
  } else if (secondary) {
    cN += ' ' + BUTTON_STYLES.SECONDARY;
  } else if (tertiary) {
    cN += ' ' + BUTTON_STYLES.TERTIARY;
  } else if (black) {
    cN += ' ' + BUTTON_STYLES.BLACK;
  } else {
    cN += ' ' + BUTTON_STYLES.DEFAULT;
  }

  if (pill) {
    cN += ' ' + BUTTON_STYLES.PILL;
  }
  if (pillNaked) {
    cN += ' hover:bg-slate-50  hover:text-gray-700 rounded-full';
  }

  if (disabled || loading) {
    cN += ' opacity-50 cursor-default pointer-events-none ';
  }

  if (large) {
    cN += ' h-12 px-8  ';
  } else if (small) {
    cN += ' h-8 px-4 text-sm ';
  } else {
    cN += ' h-10 px-6 ';
  }

  if (fullWidth) {
    cN += ' w-full ';
  }

  if (className) {
    cN += className;
  }

  return (
    <button
      className={cN}
      disabled={disabled || loading}
      type="button"
      {...props}
    >
      {!!loading && <LoadingIndicator />}
      {children}
    </button>
  );
};

export default Button;
