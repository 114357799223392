'use client';

import Link from 'next/link';

import Button from '@/components/common/Button';
import { useEffect, useState } from 'react';
import { getSession } from 'next-auth/react';

export const MenuUserButton = () => {
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    getSession().then(setSession);
  }, []);

  if (session) {
    return (
      <li>
        <Link href="/app" aria-label="Dashboard" title="Dashboard">
          <Button small className="w-full lg:w-auto">
            Dashboard
          </Button>
        </Link>
      </li>
    );
  }

  return <NotLoggedInMenuUserButton />;
};

export const NotLoggedInMenuUserButton = () => (
  <>
    <li>
      <Link
        href="/api/auth/signin"
        aria-label="Login"
        title="Login"
        className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-teal-accent-400 "
      >
        Sign in
      </Link>
    </li>
    <li>
      <Link href="/app" aria-label="Try for free" title="Try for free">
        <Button small className="w-full lg:w-auto">
          Try for free
        </Button>
      </Link>
    </li>
  </>
);
